import React from 'react';
import { Button } from "@mui/material";
import PropTypes from 'prop-types';

const CustomButtonTab = ({ label, isActive, onClick }) => {
    return (
        <Button
            sx={isActive ? {
                backgroundColor: '#5052ba',
                color: '#fff',
                '&:hover': {
                    backgroundColor: '#4042a3',
                },
                mr:2,
            } : {
                backgroundColor: '#e0e0e0', // Set background color to grey
                color: '#000',
                '&:hover': {
                    backgroundColor: '#d5d5d5', // Slightly darker grey on hover
                },
                mr:2
            }}
            onClick={onClick}
        >
            {label}
        </Button>
    );
};

CustomButtonTab.propTypes = {
    label: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default CustomButtonTab;
