import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Menu,
  MenuItem,
  Typography,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import CustomButtonTab from "../../components/CustomButtonTab/CustomButtonTab";
import SalesGroup from "./SalesGroup/SalesGroup";
import OrderBy from "./OrderBy/OrderBy";
import Payer from "./Payer/Payer";
import {
  getRequestForDownload,
  getRequestInstanceOne,
  getRequestInstanceTwo,
} from "../../apiServices/apiCall";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";
import CustomDialog from "../../components/CustomDialog/CustomDialog";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const GroupByOrderDetails = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [value, setValue] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("Today");
  const [openDialog, setOpenDialog] = useState(false);
  const [customDateRange, setCustomDateRange] = useState({
    start_date: "",
    end_date: "",
  });
  const [groupByData, setGroupByData] = useState([]);
  const [selectedGroupBy, setSelectedGroupBy] = useState("salesgroup");
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleButtonClick = (index) => {
    setValue(index);
    switch (index) {
      case 0:
        setSelectedGroupBy("salesgroup");
        localStorage.setItem("group", "salesgroup");
        break;
      case 1:
        setSelectedGroupBy("orderby");
        localStorage.setItem("group", "orderby");
        break;
      case 2:
        setSelectedGroupBy("payer");
        localStorage.setItem("group", "payer");
        break;
      default:
        setSelectedGroupBy("salesgroup");
        localStorage.setItem("group", "salesgroup");
    }
    setAnchorEl(null); // Close the menu on selection
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDateChange = (field) => (event) => {
    setCustomDateRange({
      ...customDateRange,
      [field]: event.target.value,
    });
  };

  const handleCustomRangeSubmit = () => {
    const { start_date, end_date } = customDateRange;
    getOrderList(start_date, end_date);
    setOpenDialog(false);
    setSelectedFilter(start_date + " to " + end_date);
  };

  const getOrderList = useCallback(
    async (start_date, end_date) => {
      setIsLoading(true);
      try {
        // Fetch initial data
        const params = new URLSearchParams({ start_date, end_date });
        // const url = `/counts/${selectedGroupBy}?${params.toString()}`;
        const url = `api/tc_pc_kg_pkt_counts?${params.toString()}&value=${selectedGroupBy}`;

        const { data } = await getRequestInstanceOne(url);

        // Restructure the data
        const restructureData = data.map((item, index) => ({
          id: index,
          salesgroup: item.salesgroup,
          orderby: item.order_by,
          payer: item.payerId,
          tc: item.tc,
          pc: item.pc,
          kg_sales: item.Kg,
          // pkt_sales: item.Pkt,
          pkt_sales: item.Pkts,
          orderby_id: item.orderby_id,
          // Ensure this field is included for API calls
        }));

        // Prepare for working days data fetch if any orderby_id exists
        const orderbyIds = restructureData
          .map((item) => item.orderby_id)
          .filter((id) => id)
          .join(",");

        let updatedData = restructureData;

        if (orderbyIds) {
          const workingDaysParams = new URLSearchParams({
            from_date: start_date,
            to_date: end_date,
            empId: orderbyIds,
          });
          // const workingURL = `/api/expense/working_days_range?${workingDaysParams.toString()}`;
          const workingURL = `http://143.244.133.43:5000/api/expense/working_days_range?${workingDaysParams.toString()}`;
          const response = await getRequestInstanceTwo(workingURL);
          if (response && response.result) {
            const workingDaysData = response.result;

            updatedData = restructureData.map((item) => {
              const workingDays =
                workingDaysData[item.orderby_id]?.working_days || 0;
              return {
                ...item,
                working_days: workingDays,
              };
            });
          } else {
            console.error("Error: Invalid response structure", response);
          }
        }

        // Update state
        const filteredData = orderbyIds ? updatedData : restructureData;
        // console.log("filteredData ==", filteredData);
        setGroupByData(filteredData);
      } catch (error) {
        console.error("Error fetching order list:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [selectedGroupBy]
  );

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setCustomDateRange({
      start_date: today,
      end_date: today,
    });
    setSelectedFilter("Today");
    getOrderList(today, today);

    // const intervalId = setInterval(() => {
    //     getOrderList(today, today);
    // }, 60000); // 60 seconds

    // return () => clearInterval(intervalId);
  }, [getOrderList]);

  const exportExcel = async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();
      params.append("start_date", customDateRange.start_date);
      params.append("end_date", customDateRange.end_date);

      const apiUrl = `/download_excel/${selectedGroupBy}?${params.toString()}`;
      const result = await getRequestForDownload(apiUrl);
      const res = new Blob([result]);
      const url = window.URL.createObjectURL(res);

      const a = document.createElement("a");
      a.href = url;
      a.download = `Excel_${new Date()
        .toLocaleDateString("en-GB")
        .split("/")
        .join("-")}.xlsx`; // Set the filename for the downloaded file
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setAnchorEl(null);
    const today = new Date();

    let start_date, end_date;

    switch (filter) {
      case "Today":
        start_date = end_date = today.toISOString().split("T")[0];
        break;
      case "Weekly":
        start_date = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Monthly":
        start_date = new Date(today.setMonth(today.getMonth() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Yearly":
        start_date = new Date(today.setFullYear(today.getFullYear() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Custom Range":
        setOpenDialog(true);
        return;
      default:
        return;
    }

    setCustomDateRange({
      start_date,
      end_date,
    });

    getOrderList(start_date, end_date);
  };
  // console.log("selectedGroupBy", selectedGroupBy);
  // console.log("groupByData", groupByData);
  const filteredData = searchQuery
    ? groupByData.filter((item) =>
        item[selectedGroupBy]?.toLowerCase().includes(searchQuery.trim().toLowerCase())
      )
    : groupByData;
  // console.log("filteredData", filteredData);
  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedFilter("");
  };

  return (
    <>
      <CustomLoader open={isLoading} />
      <Card sx={{ p: 1 }}>
        <Grid container spacing={2} alignItems="center" sx={{ pt: 1, pb: 1 }}>
          <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
            <IconButton onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  width: "80%",
                },
              }}
            >
              <MenuItem onClick={() => handleButtonClick(0)}>
                Sales Group
              </MenuItem>
              <MenuItem onClick={() => handleButtonClick(1)}>Order By</MenuItem>
              <MenuItem onClick={() => handleButtonClick(2)}>Payer</MenuItem>
            </Menu>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <CustomButtonTab
              label="Sales Group"
              isActive={value === 0}
              onClick={() => handleButtonClick(0)}
            />
            <CustomButtonTab
              label="Order By"
              isActive={value === 1}
              onClick={() => handleButtonClick(1)}
            />
            <CustomButtonTab
              label="Payer"
              isActive={value === 2}
              onClick={() => handleButtonClick(2)}
            />
            <Typography
              sx={{
                padding: "5px 10px",
                borderRadius: "4px",
                color: "blanchedalmond",
                background: "#5052ba",
                display: "inline-block", // Ensure inline display
                marginLeft: 5, // Add some space between the button and the text
              }}
            >{`Total Count: ${filteredData.length}`}</Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent={{ xs: "center", md: "flex-end" }}
            spacing={1}
            alignItems="center"
          >
            <Grid item xs={12} sm={3} md="auto">
              <TextField
                fullWidth
                size="small"
                type="search"
                placeholder={`Search By ${selectedGroupBy}`}
                value={searchQuery}
                onChange={handleSearch}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Tooltip
                title={`${customDateRange.start_date} ${customDateRange.end_date}`}
                placement="top"
                arrow
              >
                <Autocomplete
                  fullWidth
                  disablePortal
                  size="small"
                  sx={{ bgcolor: "#fff" }}
                  id="combo-box-demo"
                  value={selectedFilter}
                  options={[
                    "Today",
                    "Weekly",
                    "Monthly",
                    "Yearly",
                    "Custom Range",
                  ]}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => handleFilterSelect(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Date Filter" />
                  )}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={3} md="auto">
              <Button
                size="small"
                variant="contained"
                onClick={exportExcel}
                sx={{
                  backgroundColor: "#5052ba",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#4042a3",
                  },
                  ml: 2,
                  p: 1,
                }}
                startIcon={<SaveAltIcon />}
              >
                Export Excel
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ pt: 1 }}>
          <CustomTabPanel value={value} index={0}>
            {value === 0 && (
              <SalesGroup
                groupByData={filteredData}
                customDateRange={customDateRange}
              />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {value === 1 && (
              <OrderBy
                groupByData={filteredData}
                customDateRange={customDateRange}
              />
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {value === 2 && (
              <Payer
                groupByData={filteredData}
                customDateRange={customDateRange}
              />
            )}
          </CustomTabPanel>
        </Box>
      </Card>

      <CustomDialog
        open={openDialog}
        onClose={handleDialogClose}
        title={`Select Date Range`}
        fullscreen={false} // Set to true if you want the dialog to be fullscreen
        maxWidth="sm" // Set the maxWidth as needed
      >
        <TextField
          margin="dense"
          label="Start Date"
          type="date"
          fullWidth
          value={customDateRange.start_date}
          onChange={handleDateChange("start_date")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          label="End Date"
          type="date"
          fullWidth
          value={customDateRange.end_date}
          onChange={handleDateChange("end_date")}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDialogClose}
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCustomRangeSubmit}
            sx={{
              backgroundColor: "#5052ba",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#4042a3",
              },
              ml: 2,
            }}
          >
            Submit
          </Button>
        </Box>
      </CustomDialog>
    </>
  );
};

export default GroupByOrderDetails;
