import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getRequestInstanceTwo } from "../../apiServices/apiCall";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";
import SearchIcon from "@mui/icons-material/Search";

const StyledGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  backgroundColor: "#f6f5fa",
  fontFamily: "Arial, sans-serif",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0),
  fontSize: "2rem",
  fontWeight: "bold",
  textAlign: "center",
  color: "#5052ba",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  margin: "auto",
  padding: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(2),
  },
  backgroundColor: "#fff",
  borderRadius: "15px",
  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)",
  },
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "auto", // Auto height to fit content
  minHeight: 250, // Set a minimum height to ensure consistency
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: "190px", // Set a fixed height for all images
  width: "100%", // Ensure the image takes up the full width of the card
  objectFit: "inherit", // Ensure the image covers the entire area
  borderRadius: "12px",
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: `${theme.spacing(1)} ${theme.spacing(1)} 0px ${theme.spacing(1)}`,
  textAlign: "center",
  height: "auto", // Let content dictate its height
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Arial, sans-serif",
  textAlign: "center",
  color: "darkcyan",
}));

const OfferIdTypography = styled(Typography)(({ theme }) => ({
  color: "saddlebrown",
  fontWeight: "bold",
}));

const SaleOffer = () => {
  const [saleOffers, setSaleOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getRequestInstanceTwo("/api/all_saleoffers");
      setSaleOffers(response.result);
      setFilteredOffers(response.result); // Set initial filtered offers
    } catch (error) {
      console.error("Error fetching sale offers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filtered = saleOffers.filter(
      (offer) =>
        offer.offer_name.toLowerCase().includes(searchQuery.trim().toLowerCase()) ||
        offer.offer_id.toString().toLowerCase().includes(searchQuery)
    );
    setFilteredOffers(filtered);
  }, [searchQuery, saleOffers]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Split offers into Current and Expired based on specific offer IDs
  const currentOfferIds = ["MO2", "QPS1", "PMC", "NYL", "NYD", "SPD1"];
  const currentOffers = filteredOffers.filter((offer) =>
    currentOfferIds.includes(offer.offer_id)
  );
  const expiredOffers = filteredOffers.filter(
    (offer) => !currentOfferIds.includes(offer.offer_id)
  );

  const renderCards = (offers) =>
    offers.map((offer) => (
      <Grid item xs={12} sm={6} md={4} lg={2.4} key={offer.id}>
        <StyledCard>
          <Link
            to={`/sales-offer/${offer.offer_name.replace(/ /g, "")}/${
              offer.offer_id
            }?time-frame=Monthly`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <StyledCardMedia
              component="img"
              src={`http://142.93.208.119/media/${offer.image}`}
              alt={offer.offer_name}
              onError={(e) => {
                e.target.src = "https://via.placeholder.com/345x200";
              }}
            />
            <StyledCardContent>
              <StyledTypography variant="h6" component="div">
                {offer.offer_name}
              </StyledTypography>
              <OfferIdTypography variant="body2">
                Offer ID: {offer.offer_id}
              </OfferIdTypography>
            </StyledCardContent>
          </Link>
        </StyledCard>
      </Grid>
    ));

  return (
    <StyledGrid container spacing={4}>
      <CustomLoader open={isLoading} />

      {/* Search Bar */}
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <TextField
          variant="outlined"
          placeholder="Search by Offer ID or Offer Name"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {/* Current Offers */}
      <Grid item xs={12}>
        <SectionTitle>Active Offers</SectionTitle>
      </Grid>
      <Grid container spacing={4}>
        {renderCards(currentOffers)}
      </Grid>

      {/* Expired Offers */}
      <Grid item xs={12}>
        <SectionTitle sx={{ color: "#5052ba" }}>Expired Offers</SectionTitle>
      </Grid>
      <Grid container spacing={4}>
        {renderCards(expiredOffers)}
      </Grid>
    </StyledGrid>
  );
};

export default SaleOffer;
