import React, { useEffect, useState } from "react";
import { TextField, Box, Button, Grid, Autocomplete } from "@mui/material";
import {
  getRequestForDownload,
  getRequestInstanceFour,
  getRequestInstanceOne,
} from "../../../apiServices/apiCall";

const GroupByDataExportExcel = ({ setOpenDialog, customDateRange }) => {
  const today = new Date().toISOString().split("T")[0];
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptionOne, setFilterOptionOne] = useState([]);
  const [filterOptionTwo, setFilterOptionTwo] = useState([]);
  const [formData, setFormData] = useState({
    startDate: today,
    endDate: today,
    filterKey: "" || null,
    filterValue: "" || null,
  });

  const fetchData = async (filterKey) => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();
      if (customDateRange) {
        params.append("start_date", customDateRange.start_date);
        params.append("end_date", customDateRange.end_date);
      }
      if (filterKey) {
        params.append("key", filterKey);
      }
      const url = `/api/accordion_filter_excel?${params.toString()}`;
      console.log("Constructed URL:", url);
      const response = await getRequestInstanceOne(url);
      console.log("response idemid", response);
      if (response?.data?.some((item) => item._id)) {
        setFilterOptionTwo(response.data);
      } else {
        setFilterOptionOne(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "filterKey") {
      fetchData(value);
    }
  };

  const exportExcel = async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();
      params.append("start_date", formData?.startDate);
      params.append("end_date", formData?.endDate);
      const apiUrl = `/excel_download_filterwise/${formData?.filterKey}/${
        formData?.filterValue
      }?${params.toString()}`;
      const result = await getRequestForDownload(apiUrl);
      const res = new Blob([result]);
      const url = window.URL.createObjectURL(res);

      const a = document.createElement("a");
      a.href = url;
      a.download = `Excel_${formData?.startDate}_${formData?.endDate}_${formData?.filterValue}.xlsx`;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      a.remove();
      setOpenDialog(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box component="form" sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              size="small"
              sx={{ bgcolor: "#fff", zIndex: 1300 }} // Ensure higher z-index
              disablePortal={false} // Render as a direct child of the body
              id="combo-box-demo"
              value={formData.filterKey || null}
              options={filterOptionOne}
              getOptionLabel={(option) => option}
              onChange={(event, value) =>
                handleChange({ target: { name: "filterKey", value } })
              }
              renderInput={(params) => (
                <TextField {...params} label="Filter Key" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              fullWidth
              size="small"
              sx={{ bgcolor: "#fff", zIndex: 1300 }} // Ensure higher z-index
              disablePortal={false} // Render as a direct child of the body
              id="combo-box-demo"
              value={formData.filterValue || null}
              options={filterOptionTwo.map((option) => option._id)}
              getOptionLabel={(option) => option}
              onChange={(event, value) =>
                handleChange({ target: { name: "filterValue", value } })
              }
              renderInput={(params) => (
                <TextField {...params} label="Filter Value" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              label="Start Date"
              name="startDate"
              type="date"
              fullWidth
              value={formData.startDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              label="End Date"
              name="endDate"
              type="date"
              fullWidth
              value={formData.endDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpenDialog(false)}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={exportExcel}
              sx={{
                backgroundColor: "#5052ba",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#4042a3",
                },
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default GroupByDataExportExcel;
