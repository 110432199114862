import React, { useState, useEffect } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { getRequestInstanceOne } from "../../../apiServices/apiCall";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";
import * as XLSX from "xlsx";

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#d3d4ee",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: "center",
  position: "sticky",
  top: 0,
  zIndex: 1,
  fontWeight: 600,
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
  padding: "8px 12px",
}));

const TableWrapper = styled(Box)({
  width: "100%",
});

const TableContainerWrapper = styled(TableContainer)(({ theme }) => ({
  height: "350px",
  "& .MuiTableBody-root": {
    overflowY: "auto",
    height: "auto",
    minHeight: "calc(6 * 56px)",
  },
}));

// Format number into readable format
const formatNumber = (number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M"; // Convert to millions
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K"; // Convert to thousands
  } else {
    return number.toLocaleString(); // Add commas for thousands
  }
};

const ItemIdChartExportExcel = ({
  customDateRange,
  selectedItemIdView,
  setOpenDialog,
  selectedSalesgroup,
  selectedStockist,
  selectedNames,
}) => {
  const [viewData, setViewData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchQuantityData = async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();
      if (customDateRange) {
        params.append("start_date", customDateRange.start_date);
        params.append("end_date", customDateRange.end_date);
      }
      if (selectedItemIdView) {
        params.append("itemId", selectedItemIdView);
      }
      if (selectedSalesgroup) {
        params.append("salesgroup", selectedSalesgroup);
      }
      if (selectedStockist && localStorage.getItem("user") === "admin") {
        params.append("stockist", selectedStockist);
      }
      if (localStorage.getItem("user") !== "admin") {
        params.append("user", selectedStockist);
      }
      if (selectedNames) {
        params.append("name", selectedNames);
      }

      const url = `/api/item_history?${params.toString()}`;
      const response = await getRequestInstanceOne(url);
      setViewData(response.data);
      setFilteredData(response.data); // Initialize filtered data
      setOpenDialog(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchQuantityData();
  }, [selectedItemIdView]);

  // Filter data based on search term
  useEffect(() => {
    const trimmedSearchTerm = searchTerm.trimEnd().toLowerCase(); // Trim trailing spaces and convert to lowercase
    if (trimmedSearchTerm) {
      const newFilteredData = viewData.filter((item) =>
        [
          item.salesgroup,
          item.orderId,
          item.payerId,
          item.stockist,
          item.customer,
          item.area,
          item.orderbyName,
        ].some(
          (field) => field && field.toLowerCase().includes(trimmedSearchTerm)
        )
      );
      setFilteredData(newFilteredData);
    } else {
      setFilteredData(viewData);
    }
  }, [searchTerm, viewData]);

  // Calculate total quantity
  const calculateTotalQuantity = () => {
    return filteredData.reduce(
      (total, item) => total + Number(item.qty || 0),
      0
    ); // Ensure qty is a number
  };

  const exportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${selectedItemIdView}.xlsx`);
  };

  // Making text ellipsis... start

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // Making text ellipsis... end

  return (
    <>
      <CustomLoader open={isLoading} />
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: searchTerm && (
                <IconButton
                  onClick={() => setSearchTerm("")}
                  aria-label="clear"
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
            sx={{ width: "30%" }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              backgroundColor: "#5052ba",
              padding: "8px",
              borderRadius: "4px",
              color: "wheat",
            }}
          >
            Total Quantity: {formatNumber(calculateTotalQuantity())}
          </Typography>
          <Button
            size="small"
            variant="contained"
            onClick={exportExcel}
            sx={{
              backgroundColor: "#5052ba",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#4042a3",
              },
              p: 1,
            }}
            startIcon={<SaveAltIcon />}
          >
            Export Excel
          </Button>
        </Box>
        <TableWrapper className="baba">
          <TableContainerWrapper component={Paper} style={{ height: "90vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    SalesGroup
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Order ID
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Payer ID
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Stockist
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Customer
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Area
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Order By Name
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Location
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Price
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Order On
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Cash Discount
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      padding: "0 5px",
                      textAlign: "center",
                    }}
                  >
                    Discount
                  </StyledTableCell>
                  <StyledTableCell>Free</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((item, index) => (
                  <StyledTableRow key={index}>
                    <Tooltip
                      title={item.salesgroup || "-"}
                      placement="top"
                      arrow
                    >
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.salesgroup || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={item.orderId || "-"} placement="top" arrow>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.orderId || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={item.payerId || "-"} placement="top" arrow>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.payerId || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={item.stockist || "-"} placement="top" arrow>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.stockist || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={item.customer || "-"} placement="top" arrow>
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.customer || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <Tooltip title={item.area || "-"} placement="top" arrow>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.area || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <Tooltip
                      title={item.orderbyName || "-"}
                      placement="top"
                      arrow
                    >
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px", // You can adjust the width based on your layout
                        }}
                      >
                        {truncateText(item.orderbyName || "-", 15)}
                      </TableCell>
                    </Tooltip>

                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.longitude && item.lattitude ? (
                        <a
                          href={`https://www.google.com/maps?q=${item.longitude},${item.lattitude}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconButton>
                            <LocationOnIcon />
                          </IconButton>
                        </a>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.price}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.qty}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.orderOn}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.cash_disc}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.discount}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0 5px",
                      }}
                    >
                      {item.free}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainerWrapper>
        </TableWrapper>
      </Box>
    </>
  );
};

export default ItemIdChartExportExcel;
