import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { getRequestInstanceOne } from "../../apiServices/apiCall";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Link as MuiLink,
  Box,
  styled,
  TextField,
  Button,
  Autocomplete,
  Grid,
  Tooltip,
} from "@mui/material";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import * as XLSX from "xlsx";

const StyledTableCell = styled(TableCell)(({ theme, isHeader, isFooter }) => ({
  backgroundColor: isHeader ? "#d3d4ee" : isFooter ? "#f5f5f5" : "transparent",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: "center",
  position: isHeader || isFooter ? "sticky" : "relative",
  top: isHeader ? 0 : "auto",
  bottom: isFooter ? 0 : "auto",
  zIndex: isHeader || isFooter ? 1 : "auto",
  fontWeight: 600,
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
  padding: "8px 12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    padding: "6px 10px",
  },
}));

const TableWrapper = styled(Box)({
  width: "100%",
  height: "100%",
  overflowX: "auto",
});

const useStyles = makeStyles(() => ({
  search: {
    margin: "0",
  },
  clearIcon: {
    cursor: "pointer",
  },
}));

const Attendance = () => {
  const [salesDetails, setSalesDetails] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [customDateRange, setCustomDateRange] = useState({
    start_date: "",
    end_date: "",
  });

  const [showClearIcon, setShowClearIcon] = useState("none");
  const { search, clearIcon } = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [uniqueEmpid, setUniqueEmpid] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [attendanceDaysCount, setAttendanceDaysCount] = useState([]);

  const fetchEmpData = async (start_date, end_date) => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();
      params.append("start_date", start_date);
      params.append("end_date", end_date);

      const url1 = `/api/emp_id?${params.toString()}`;
      const response1 = await getRequestInstanceOne(url1);

      // const [response1] = await Promise.all([
      //   fetch(url1).then((res) => res.json()),
      // ]);

      // Make unique based on empId
      const uniqueSalesDetails = Object.values(
        response1.data.reduce((acc, current) => {
          acc[current.empId] = current;
          return acc;
        }, {})
      );
      console.log("uniqueSalesDetails", uniqueSalesDetails);
      // Assuming we only need to use data from url1 in this example
      setSalesDetails(response1.data);

      setUniqueEmpid(uniqueSalesDetails);

      return response1;
      // exportDetailedExcel(response1);
      // console.log(response1.data[0][0].empId);
    } catch (error) {
      console.error("Error fetching sales details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Trigger fetchEmpData when debounceValue or customDateRange changes
  // useEffect(() => {
  //   let timerOut = setTimeout(() => {
  //     fetchEmpData(customDateRange.start_date, customDateRange.end_date);
  //   }, 800);
  //   return () => clearTimeout(timerOut);
  // }, [customDateRange, searchValue]);

  const fetchEmpTimeData = async (start_date, end_date, emp_id) => {
    setIsLoading(true);
    setViewData("");
    try {
      const params = new URLSearchParams();
      params.append("start_date", start_date);
      params.append("end_date", end_date);

      const url2 = `http://143.244.133.43:5000/api/expense/user_login_logout_details?${params.toString()}&empId=${emp_id}`;

      const [response2] = await Promise.all([
        fetch(url2).then((res) => res.json()),
      ]);
      // console.log("url2 Response", response2);

      setViewData(response2.result);
      // console.log("response2", response2.result);
      return response2;
    } catch (error) {
      console.error("Error fetching sales details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  let attendanceData = [];
  const fetchEmpTimeDataExcel = async (start_date, end_date, allEmpIds) => {
    setIsLoading(true);
    const allAttendanceData = [];

    try {
      const params = new URLSearchParams();
      params.append("start_date", start_date);
      params.append("end_date", end_date);
      params.append("empId", allEmpIds);

      const url2 = `http://143.244.133.43:5000/api/expense/user_login_logout_details?${params.toString()}`;
      

      const response1 = await fetch(url2).then((res) => res.json());

      if (response1.result) {
        allAttendanceData.push(...response1.result);
      }

      const workingDaysCount = `http://143.244.133.43:5000/api/expense/working_days_range?from_date=${start_date}&to_date=${end_date}&empId=${allEmpIds}`

      const response2 = await fetch(workingDaysCount).then((res) => res.json())
      const result = response2.result;
      
      // Construct an array or object to store attendance days count
      attendanceData = Object.keys(result).map((id) => ({
        empId: id,
        working_days: result[id].working_days,
      }));

      return allAttendanceData;
    } catch (error) {
      console.error("Error fetching attendance details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFilterSelect("Today");
  }, []);

  const handleDateChange = (field) => (event) => {
    setCustomDateRange({
      ...customDateRange,
      [field]: event.target.value,
    });
  };

  const handleCustomRangeSubmit = () => {
    const { start_date, end_date } = customDateRange;
    fetchEmpData(start_date, end_date);
    fetchEmpTimeData(start_date, end_date);
    fetchEmpTimeDataExcel(start_date, end_date);
    setOpenDialog(false);
    setSelectedFilter(start_date + " to " + end_date);
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    const today = new Date();
    let start_date, end_date;

    switch (filter) {
      case "Today":
        start_date = end_date = today.toISOString().split("T")[0];
        break;
      case "Weekly":
        start_date = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Monthly":
        start_date = new Date(today.setMonth(today.getMonth() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Yearly":
        start_date = new Date(today.setFullYear(today.getFullYear() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Custom Range":
        setOpenDialog(true);
        return;
      default:
        return;
    }

    setCustomDateRange({
      start_date,
      end_date,
    });

    fetchEmpData(start_date, end_date);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedFilter("");
  };

  //   Search functionality
  const handleChange = (event) => {
    setSearchValue(event.target.value);
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
  };

  const handleClick = () => {
    setSearchValue("");
    setShowClearIcon("none");
  };

  const normalizeSearchValue = (value) => {
    return value.trim().replace(/\s+/g, " ").toLowerCase();
  };

  // Filter filteredEmps based on searchValue
  const filteredEmps = uniqueEmpid.filter((emp) => {
    const emp_name = emp.name ? emp.name.toLowerCase() : "";
    const emp_id = emp.empId ? emp.empId.toString().toLowerCase() : "";

    const normalizedSearchValue = normalizeSearchValue(searchValue);

    const nameMatches = normalizedSearchValue
      ? emp_name.includes(normalizedSearchValue)
      : true;

    const idMatches = normalizedSearchValue
      ? emp_id.includes(normalizedSearchValue)
      : true;

    return nameMatches || idMatches;
  });

  const handleopenView = (id) => {
    const settingview = salesDetails.filter((item) => item.empId === id); // Use `find` instead of `filter` to get a single item

    // console.log("open view", settingview);

    setSelectedEmployee(settingview); // Store the selected employee details

    // console.log("SelectedEmployee", selectedEmployee);

    fetchEmpTimeData(customDateRange.start_date, customDateRange.end_date, id);
    setOpenViewDialog(true);
  };

  // function for generating unique sheet in excel
  // const generateUniqueSheetName = (workbook, baseName) => {
  //   let sheetName = baseName;
  //   let counter = 1;
  //   while (workbook.SheetNames.includes(sheetName)) {
  //     sheetName = `${baseName}(${counter})`;
  //     counter++;
  //   }
  //   return sheetName;
  // };

  const handleExportClick = () => {
    exportAllDataToExcel(customDateRange.start_date, customDateRange.end_date);
  };

  // const exportAllDataToExcel = async (start_date, end_date) => {
  //   setIsLoading(true);

  //   try {
  //     const empDataResponse = await fetchEmpData(start_date, end_date);
  //     const empData = empDataResponse.data;

  //     // Extract unique empIds from the first API response
  //     const empIds = [...new Set(empData.map((emp) => emp.empId))];

  //     // Create a map of EmpIds with their Details
  //     const idDateMap = new Map();
  //     empData.forEach((item) => {
  //       if (!idDateMap.has(item.empId)) {
  //         idDateMap.set(item.empId, []);
  //       }
  //       idDateMap.get(item.empId).push({
  //         date: item.date,
  //       });
  //     });

  //     const allEmpIds = Array.from(idDateMap.keys()).join(",");

  //     console.log(allEmpIds, "All EmpIds");

  //     // Fetch all relevant attendance data using the empIds
  //     const allAttendanceData = await fetchEmpTimeDataExcel(
  //       start_date,
  //       end_date,
  //       allEmpIds
  //     );

  //     // Organize attendance data by emp_id and date
  //     const attendanceByEmpId = {};
  //     allAttendanceData.forEach((record) => {
  //       const { emp_id, ua_date } = record;
  //       if (!attendanceByEmpId[emp_id]) {
  //         attendanceByEmpId[emp_id] = {};
  //       }
  //       attendanceByEmpId[emp_id][ua_date] = record;
  //     });

  //     console.log("Attendance by Emp ID:", attendanceByEmpId); // Log organized attendance data

  //     // Create a new workbook
  //     const workbook = XLSX.utils.book_new();

  //     // Create separate sheets for each empId
  //     const empDataById = {};

  //     empData.forEach((emp) => {
  //       const empId = emp.empId;
  //       if (!empDataById[empId]) {
  //         empDataById[empId] = {};
  //       }
  //       empDataById[empId][emp.date] = emp;
  //     });

  //     Object.keys(empDataById).forEach((empId) => {
  //       const baseSheetName = empId;
  //       const sheetName = generateUniqueSheetName(workbook, baseSheetName);

  //       // Prepare data for the sheet
  //       const sheetData = [
  //         {
  //           Date: "Date",
  //           ua_id: "UA ID",
  //           empId: "Employee ID",
  //           name: "Name",
  //           Attendance: "Attendance",
  //           logInTime: "Log In Time",
  //           first_call: "First Call",
  //           last_call: "Last Call",
  //           logOutTime: "Log Out Time",
  //           Tc: "Tc",
  //           Pc: "Pc",
  //           total_qty_Kg: "Kg",
  //           total_qty_Pkt: "Pkt",
  //           "Total Qty": "Total",
  //         },
  //       ];

  //       const uniqueDates = new Set([
  //         ...Object.keys(empDataById[empId]),
  //         ...Object.keys(attendanceByEmpId[empId] || {}),
  //       ]);

  //       const sortedDates = Array.from(uniqueDates).sort(
  //         (a, b) => new Date(a) - new Date(b)
  //       );

  //       sortedDates.forEach((date) => {
  //         const emp = empDataById[empId][date] || {};
  //         const attendance = attendanceByEmpId[empId]?.[date] || {};

  //         // Convert values to numbers for accurate summation; handle any non-numeric cases
  //         const total_qty_Kg = parseFloat(emp.total_qty_Kg) || 0;
  //         const total_qty_Pkt = parseFloat(emp.total_qty_Pkt) || 0;

  //         const combinedRow = {
  //           Date: date,
  //           ua_id: attendance.ua_id || "N/A",
  //           empId: emp.empId || empId,
  //           name: emp.name || "N/A",
  //           Attendance: attendance.attendance || "N/A",
  //           logInTime: attendance.logInTime || "N/A",
  //           first_call: emp.first_call || "N/A",
  //           last_call: emp.last_call || "N/A",
  //           logOutTime: attendance.logOutTime || "N/A",
  //           Tc: emp.Tc || "N/A",
  //           Pc: emp.Pc || "N/A",
  //           total_qty_Kg: emp.total_qty_Kg || "N/A",
  //           total_qty_Pkt: emp.total_qty_Pkt || "N/A",
  //           "Total Qty": total_qty_Kg + total_qty_Pkt,
  //         };

  //         sheetData.push(combinedRow);
  //       });

  //       // Add the sheet to the workbook
  //       const worksheet = XLSX.utils.json_to_sheet(sheetData, {
  //         skipHeader: true,
  //       });
  //       XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  //     });

  //     // Export the workbook
  //     XLSX.writeFile(workbook, `EmployeeData_${start_date}_${end_date}.xlsx`);
  //   } catch (error) {
  //     console.error("Error exporting data to Excel:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const exportAllDataToExcel = async (start_date, end_date) => {
    setIsLoading(true);

    try {
      const empDataResponse = await fetchEmpData(start_date, end_date);
      const empData = empDataResponse.data;

      // Extract unique empIds from the first API response
      const empIds = [...new Set(empData.map((emp) => emp.empId))];

      // Create a map of EmpIds with their Details
      const idDateMap = new Map();
      empData.forEach((item) => {
        if (!idDateMap.has(item.empId)) {
          idDateMap.set(item.empId, []);
        }
        idDateMap.get(item.empId).push({
          date: item.date,
        });
      });

      const allEmpIds = Array.from(idDateMap.keys()).join(",");

      // Fetch all relevant attendance data using the empIds
      const allAttendanceData = await fetchEmpTimeDataExcel(
        start_date,
        end_date,
        allEmpIds
      );

      // Organize attendance data by emp_id and date
      const attendanceByEmpId = {};
      allAttendanceData.forEach((record) => {
        const { emp_id, ua_date } = record;
        if (!attendanceByEmpId[emp_id]) {
          attendanceByEmpId[emp_id] = {};
        }
        attendanceByEmpId[emp_id][ua_date] = record;
      });

      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      const sheetData = [
        {
          Date: "Date",
          ua_id: "UA ID",
          empId: "Employee ID",
          payerid: "Payer ID",
          name: "Name",
          salesgroup: "Salesgroup",
          Attendance: "Attendance",
          logInTime: "Log In Time",
          first_call: "First Call",
          last_call: "Last Call",
          logOutTime: "Log Out Time",
          Tc: "Tc",
          Pc: "Pc",
          total_qty_Kg: "Kg",
          total_qty_Pkt: "Pkt",
          "Total Qty": "Total",
          // beat_name: "Beat Name",
          // logInFile: "LogIn File",
          // logInLocation: "LogIn Location",
          // logOutFile: "LogOut File",
          // logOutLocation: "LogOut Location",
          // pjp_info: "PJP Info",
          // stockist_info: "Stockist Info",
        },
      ];

      const empDataById = {};
      empData.forEach((emp) => {
        const empId = emp.empId;
        if (!empDataById[empId]) {
          empDataById[empId] = {};
        }
        empDataById[empId][emp.date] = emp;
      });

      // Combine data for all employees into a single sheet
      Object.keys(empDataById).forEach((empId) => {
        const uniqueDates = new Set([
          ...Object.keys(empDataById[empId]),
          ...Object.keys(attendanceByEmpId[empId] || {}),
        ]);

        const sortedDates = Array.from(uniqueDates).sort(
          (a, b) => new Date(a) - new Date(b)
        );

        sortedDates.forEach((date) => {
          const emp = empDataById[empId][date] || {};
          const attendance = attendanceByEmpId[empId]?.[date] || {};

          // Convert values to numbers for accurate summation; handle any non-numeric cases
          const total_qty_Kg = parseFloat(emp.total_qty_Kg) || 0;
          const total_qty_Pkt = parseFloat(emp.total_qty_Pkt) || 0;

          const combinedRow = {
            Date: date,
            ua_id: attendance.ua_id || "N/A",
            empId: emp.empId || empId,
            payerid: emp.payerid || "N/A",
            name: emp.name || "N/A",
            salesgroup: emp.salesgroup || "N/A",
            Attendance: attendance.attendance || "N/A",
            logInTime: attendance.logInTime || "N/A",
            first_call: emp.first_call || "N/A",
            last_call: emp.last_call || "N/A",
            logOutTime: attendance.logOutTime || "N/A",
            Tc: emp.Tc || "N/A",
            Pc: emp.Pc || "N/A",
            total_qty_Kg: emp.total_qty_Kg || "N/A",
            total_qty_Pkt: emp.total_qty_Pkt || "N/A",
            "Total Qty": total_qty_Kg + total_qty_Pkt,
            // beat_name: attendance.beat_name || "N/A",
            // logInFile: attendance.logInFile || "N/A",
            // logInLocation: attendance.logInLocation || "N/A",
            // logOutFile: attendance.logOutFile || "N/A",
            // logOutLocation: attendance.logOutLocation || "N/A",
            // pjp_info: attendance.pjp_info || "N/A",
            // stockist_info: attendance.stockist_info || "N/A",
          };

          sheetData.push(combinedRow);
        });
      });

      // Create a single sheet for all employee data
      const worksheet = XLSX.utils.json_to_sheet(sheetData, {
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(workbook, worksheet, "EmployeeData");

      exportSummaryToExcel(workbook);

      // Export the workbook
      XLSX.writeFile(workbook, `EmployeeData_${start_date}_${end_date}.xlsx`);
    } catch (error) {
      console.error("Error exporting data to Excel:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportSummaryToExcel = (wb) => {
    // Create a new array to store the table data
    const tableData = [];

    // Add the header row
    tableData.push([
      "Employee ID",
      "Name",
      "Salesgroup",
      "TC",
      "PC",
      "Kg",
      "Pkt",
      "Total Qty",
      "Working Days",
    ]);

    // Loop through filteredEmps to add the row data
    filteredEmps.forEach((row) => {
      const empData = [
        row.empId || "-",
        row.name || "-",
        row.salesgroup || "-",
        salesDetails
          .filter((item) => item.empId === row.empId)
          .reduce((sum, emp) => sum + parseFloat(emp.Tc || 0), 0),
        salesDetails
          .filter((item) => item.empId === row.empId)
          .reduce((sum, emp) => sum + parseFloat(emp.Pc || 0), 0),
        salesDetails
          .filter((item) => item.empId === row.empId)
          .reduce((sum, emp) => sum + parseFloat(emp.total_qty_Kg || 0), 0),
        salesDetails
          .filter((item) => item.empId === row.empId)
          .reduce((sum, emp) => sum + parseFloat(emp.total_qty_Pkt || 0), 0),
        salesDetails
          .filter((item) => item.empId === row.empId)
          .reduce(
            (sum, emp) =>
              sum +
              (parseFloat(emp.total_qty_Kg || 0) +
                parseFloat(emp.total_qty_Pkt || 0)),
            0
          ),
          // attendanceData.forEach((emp) => {
          //   if (emp.empId === row.empId) 
          //     {
          //     console.log(`ID: ${emp.empId} and ${row.empId}, Working Days: ${emp.working_days}`);
          //     return emp.working_days || 0;
          //   }
          // })  
          attendanceData
            .filter((emp) => emp.empId === row.empId)
            .reduce((acc, emp) => acc + `${emp.working_days || 0}\n`, '')
      ];

      tableData.push(empData);
    });

    // Create a new worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(tableData);

    // Create a new workbook and add the worksheet
    XLSX.utils.book_append_sheet(wb, worksheet, "Summary");

    // Export the workbook
    // XLSX.writeFile(workbook, "employee_data.xlsx");
  };

  const exportToExcel = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Initialize an array to hold the data that will be exported
    const exportData = [];

    // Combine data from selectedEmployee and viewData arrays, matching by date
    const combinedDates = [
      ...new Set([
        ...selectedEmployee.map((emp) => emp.date),
        ...(Array.isArray(viewData)
          ? viewData.map((view) => view.ua_date)
          : []),
      ]),
    ].sort(); // Sort dates if necessary

    // Loop through each unique date
    combinedDates.forEach((date) => {
      const empData = selectedEmployee.filter((emp) => emp.date === date);
      const attendanceData = Array.isArray(viewData)
        ? viewData.filter((view) => view.ua_date === date)
        : [];

      // Get the max length to iterate over both arrays
      const maxRows = Math.max(empData.length, attendanceData.length);

      // For each row, combine employee and attendance data
      for (let j = 0; j < maxRows; j++) {
        exportData.push({
          Date: date || "-",
          EmployeeId: empData[j]?.empId || attendanceData[j]?.emp_id || "-",
          Payerid: empData[j]?.payerid || "-",
          Name: empData[j]?.name || "-",
          salesgroup: empData[j]?.salesgroup || "-",
          Attendance: attendanceData[j]?.attendance || "-",
          LoginTime: attendanceData[j]?.logInTime || "-",
          FirstCall: empData[j]?.first_call || "-",
          LastCall: empData[j]?.last_call || "-",
          LogoutTime: attendanceData[j]?.logOutTime || "-",
          Tc: empData[j]?.Tc || "0",
          Pc: empData[j]?.Pc || "0",
          Kg: empData[j]?.total_qty_Kg || "0",
          Pkt: empData[j]?.total_qty_Pkt || "0",
          TotalQty:
            parseFloat(empData[j]?.total_qty_Kg || 0) +
              parseFloat(empData[j]?.total_qty_Pkt || 0) || "0",
        });
      }
    });

    // Convert the data to a worksheet
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Employee Summary");

    // Export the workbook
    XLSX.writeFile(wb, "EmployeeSummary.xlsx");
  };

  return (
    <>
      <CustomLoader open={isLoading} />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              variant="contained"
              onClick={handleExportClick}
              sx={{
                backgroundColor: "#5052ba",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#4042a3",
                },
                p: 1,
                ml: 2,
              }}
              startIcon={<SaveAltIcon />}
            >
              Export All Details
            </Button>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <FormControl className={search} fullWidth>
              <TextField
                size="small"
                variant="outlined"
                sx={{ bgcolor: "#fff", width: 300 }}
                value={searchValue}
                onChange={handleChange}
                placeholder="Search by Id or Name"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchValue ? (
                        <ClearIcon
                          className={clearIcon}
                          onClick={handleClick}
                        />
                      ) : (
                        <SearchIcon style={{ display: setShowClearIcon }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Tooltip
              title={
                selectedFilter === "Today"
                  ? customDateRange.start_date
                  : `${customDateRange.start_date} ${customDateRange.end_date}`
              }
              placement="top"
            >
              <Autocomplete
                disablePortal
                size="small"
                sx={{ bgcolor: "#fff", width: 200 }}
                id="combo-box-demo"
                value={selectedFilter}
                options={[
                  "Today",
                  "Weekly",
                  "Monthly",
                  "Yearly",
                  "Custom Range",
                ]}
                getOptionLabel={(option) => option}
                onChange={(event, value) => handleFilterSelect(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Date Filter" />
                )}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom>
        Summary Table
      </Typography>

      <TableWrapper>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "450px", overflow: "auto" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell isHeader>Date</StyledTableCell> */}
                <StyledTableCell isHeader>Name</StyledTableCell>
                <StyledTableCell isHeader>Employee ID</StyledTableCell>
                {/* <StyledTableCell isHeader>First Call</StyledTableCell>
                <StyledTableCell isHeader>Last Call</StyledTableCell> */}
                <StyledTableCell isHeader>Salesgroup</StyledTableCell>
                <StyledTableCell isHeader>TC</StyledTableCell>
                <StyledTableCell isHeader>PC</StyledTableCell>
                <StyledTableCell isHeader>Kg</StyledTableCell>
                <StyledTableCell isHeader>Pkt</StyledTableCell>
                <StyledTableCell isHeader>Total Qty</StyledTableCell>
                <StyledTableCell isHeader>Detail</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEmps.map((row, index) => (
                <StyledTableRow key={index}>
                  {/* <TableCell align="center">{row.date || "-"}</TableCell> */}
                  <TableCell align="center" sx={{ padding: "2px 2px" }}>
                    {row.name || "-"}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "2px 2px" }}>
                    {row.empId || "-"}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "2px 2px" }}>
                    {row.salesgroup || "-"}
                  </TableCell>
                  {/* <TableCell align="center">{row.first_call || "-"}</TableCell>
                  <TableCell align="center">{row.last_call || "-"}</TableCell> */}
                  <TableCell align="center" sx={{ padding: "2px 2px" }}>
                    {salesDetails
                      .filter((item) => item.empId === row.empId)
                      .reduce((sum, emp) => sum + parseFloat(emp.Tc || 0), 0)}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "2px 2px" }}>
                    {salesDetails
                      .filter((item) => item.empId === row.empId)
                      .reduce((sum, emp) => sum + parseFloat(emp.Pc || 0), 0)}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "2px 2px" }}>
                    {salesDetails
                      .filter((item) => item.empId === row.empId)
                      .reduce(
                        (sum, emp) => sum + parseFloat(emp.total_qty_Kg || 0),
                        0
                      )}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "2px 2px" }}>
                    {salesDetails
                      .filter((item) => item.empId === row.empId)
                      .reduce(
                        (sum, emp) => sum + parseFloat(emp.total_qty_Pkt || 0),
                        0
                      )}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: "2px 2px" }}>
                    {salesDetails
                      .filter((item) => item.empId === row.empId)
                      .reduce(
                        (sum, emp) =>
                          sum +
                          (parseFloat(emp.total_qty_Kg || 0) +
                            parseFloat(emp.total_qty_Pkt || 0)),
                        0
                      )}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ padding: "2px 2px" }}
                    // sx={{ width: "50px", padding: "4px" }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={() => handleopenView(row.empId)}
                      disabled={row.Quantity === 0}
                    >
                      View
                    </Button>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>

      <CustomDialog
        open={openDialog}
        onClose={handleDialogClose}
        title="Select Date Range"
        fullscreen={false}
        maxWidth="sm"
      >
        <TextField
          margin="dense"
          label="Start Date"
          type="date"
          fullWidth
          value={customDateRange.start_date}
          onChange={handleDateChange("start_date")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          label="End Date"
          type="date"
          fullWidth
          value={customDateRange.end_date}
          onChange={handleDateChange("end_date")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDialogClose}
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCustomRangeSubmit}
            sx={{
              backgroundColor: "#5052ba",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#4042a3",
              },
              ml: 2,
            }}
          >
            Submit
          </Button>
        </Box>
      </CustomDialog>

      <CustomDialog
        open={openViewDialog}
        onClose={() => setOpenViewDialog(false)}
        title={`Employee Summary`}
        fullscreen={true} // Set to true if you want the dialog to be fullscreen
        maxWidth="xl" // Set the maxWidth as needed
      >
        {/* Add a container for the header to hold the title and the Export button */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Employee Summary</Typography>
          <Button
            variant="contained"
            // color="primary"
            sx={{
              backgroundColor: "#5052ba",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#4042a3",
              },
              p: 1,
              ml: 2,
            }}
            onClick={exportToExcel} // Replace this with your export function
          >
            Export to Excel
          </Button>
        </Box>

        <TableWrapper>
          {/* <TableContainerWrapper component={Paper}> */}
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell isHeader>Date</StyledTableCell>
                <StyledTableCell isHeader>Name</StyledTableCell>
                <StyledTableCell isHeader>Employee Id</StyledTableCell>
                <StyledTableCell isHeader>Payer Id</StyledTableCell>
                <StyledTableCell isHeader>Salesgroup</StyledTableCell>
                <StyledTableCell isHeader>Attendance</StyledTableCell>
                <StyledTableCell isHeader>Login Time</StyledTableCell>
                <StyledTableCell isHeader>First Call</StyledTableCell>
                <StyledTableCell isHeader>Last Call</StyledTableCell>
                <StyledTableCell isHeader>Logout Time</StyledTableCell>
                {/* <StyledTableCell isHeader>Date</StyledTableCell> */}
                <StyledTableCell isHeader>Tc</StyledTableCell>
                <StyledTableCell isHeader>Pc</StyledTableCell>
                <StyledTableCell isHeader>Kg</StyledTableCell>
                <StyledTableCell isHeader>Pkt</StyledTableCell>
                <StyledTableCell isHeader>Total Qty</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {selectedEmployee?.length > 0 || Array.isArray(viewData) ? (
                [
                  ...new Set([
                    ...selectedEmployee.map((emp) => emp.date),
                    ...(Array.isArray(viewData)
                      ? viewData.map((view) => view.ua_date)
                      : []),
                  ]),
                ]
                  .sort() // Sort dates if necessary
                  .map((date, i) => {
                    const empData = selectedEmployee.filter(
                      (emp) => emp.date === date
                    );
                    const attendanceData = Array.isArray(viewData)
                      ? viewData.filter((view) => view.ua_date === date)
                      : [];

                    // Get the max length to iterate over both arrays
                    const maxRows = Math.max(
                      empData.length,
                      attendanceData.length
                    );

                    return Array.from({ length: maxRows }).map((_, j) => (
                      <StyledTableRow key={`${i}-${j}`}>
                        <TableCell
                          align="center"
                          sx={{ width: "85px", padding: "2px 4px" }}
                        >
                          {date || "-"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {empData[j]?.name || "-"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {empData[j]?.empId ||
                            attendanceData[j]?.emp_id ||
                            "-"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {empData[j]?.payerid || "-"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {empData[j]?.salesgroup || "-"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {attendanceData[j]?.attendance || "-"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {attendanceData[j]?.logInTime || "-"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {empData[j]?.first_call || "-"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {empData[j]?.last_call || "-"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {attendanceData[j]?.logOutTime || "-"}
                        </TableCell>
                        {/* <TableCell align="center">{attendanceData[j]?.ua_date || "-"}</TableCell> */}
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {empData[j]?.Tc || "0"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {empData[j]?.Pc || "0"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {empData[j]?.total_qty_Kg || "0"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {empData[j]?.total_qty_Pkt || "0"}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: "2px 2px" }}>
                          {parseFloat(empData[j]?.total_qty_Kg) +
                            parseFloat(empData[j]?.total_qty_Pkt) || "0"}
                        </TableCell>
                      </StyledTableRow>
                    ));
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    ------
                  </TableCell>
                </TableRow>
              )}

              {/* Calculate and display totals */}
              {selectedEmployee?.length > 0 && (
                <StyledTableRow>
                  <TableCell colSpan={10} align="right">
                    Total:
                  </TableCell>
                  <TableCell align="center">
                    {selectedEmployee.reduce(
                      (sum, emp) => sum + parseFloat(emp.Tc || 0),
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {selectedEmployee.reduce(
                      (sum, emp) => sum + parseFloat(emp.Pc || 0),
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {selectedEmployee.reduce(
                      (sum, emp) => sum + parseFloat(emp.total_qty_Kg || 0),
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {selectedEmployee.reduce(
                      (sum, emp) => sum + parseFloat(emp.total_qty_Pkt || 0),
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {selectedEmployee.reduce(
                      (sum, emp) =>
                        sum +
                        (parseFloat(emp.total_qty_Kg || 0) +
                          parseFloat(emp.total_qty_Pkt || 0)),
                      0
                    )}
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
          {/* </TableContainerWrapper> */}
        </TableWrapper>
      </CustomDialog>
    </>
  );
};

export default Attendance;
