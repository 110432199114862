import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import OrderTable from "./OrderTable/OrderTable";
import PropTypes from "prop-types";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import MenuIcon from "@mui/icons-material/Menu";
import NoOrderTable from "./NoOrderTable/NoOrderTable";
import AllOrderTable from "./AllOrderTable/AllOrderTable";
import CancelOrderTable from "./CancelOrderTable/CancelOrderTable";
import CustomButtonTab from "../../../components/CustomButtonTab/CustomButtonTab"; // Import CustomButtonTab
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import ExportExcel from "./ExportExcel";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const OrderDetails = () => {
  const [value, setValue] = useState(2);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [totalRowCount, settotalRowCount] = useState(0);
  const [totalRowCount1, settotalRowCount1] = useState(0);
  const [totalRowCount2, settotalRowCount2] = useState(0);
  const [totalRowCount3, settotalRowCount3] = useState(0);
  const [SearchVal, setSearchVal] = useState("");

  const handleButtonClick = (index) => {
    setValue(index);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Grid container spacing={2} alignItems="center" sx={{ pt: 1, pb: 1 }}>
          <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
            <IconButton onClick={handleMenuClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  width: "80%",
                },
              }}
            >
              <MenuItem onClick={() => handleButtonClick(0)}>Order</MenuItem>
              <MenuItem onClick={() => handleButtonClick(1)}>No Order</MenuItem>
              <MenuItem onClick={() => handleButtonClick(2)}>
                All Order
              </MenuItem>
              <MenuItem onClick={() => handleButtonClick(3)}>Cancel</MenuItem>
            </Menu>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <CustomButtonTab
              label="Order"
              isActive={value === 0}
              onClick={() => handleButtonClick(0)}
            />
            <CustomButtonTab
              label="No Order"
              isActive={value === 1}
              onClick={() => handleButtonClick(1)}
            />
            <CustomButtonTab
              label="All Order"
              isActive={value === 2}
              onClick={() => handleButtonClick(2)}
            />
            <CustomButtonTab
              label="Cancel"
              isActive={value === 3}
              onClick={() => handleButtonClick(3)}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            container
            justifyContent={{ xs: "center", md: "flex-end" }}
            spacing={1}
            alignItems="center"
          >
            <Typography
              sx={{
                padding: "7px 10px",
                borderRadius: "4px",
                color: "blanchedalmond",
                background: "#5052ba",
                display: "inline-block",
                marginLeft: 2,
              }}
            >{`Total Count: ${totalRowCount}`}</Typography>

            <Grid item xs={12} sm={3} md="auto" style={{ paddingTop: "0" }}>
              <Button
                size="small"
                variant="contained"
                onClick={() => setOpenDialog(true)}
                sx={{
                  backgroundColor: "#5052ba",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#4042a3",
                  },
                  ml: 2,
                  p: 1,
                }}
                startIcon={<SaveAltIcon />}
              >
                Export Excel
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ pt: 1 }}>
          <CustomTabPanel value={value} index={0}>
            <OrderTable
              SettotalRowCount={settotalRowCount}
              SetSearchVal={setSearchVal}
              searchVal={SearchVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <NoOrderTable
              SettotalRowCount1={settotalRowCount}
              SetSearchVal={setSearchVal}
              searchVal={SearchVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <AllOrderTable
              SettotalRowCount2={settotalRowCount}
              SetSearchVal={setSearchVal}
              searchVal={SearchVal}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <CancelOrderTable
              SettotalRowCount3={settotalRowCount}
              SetSearchVal={setSearchVal}
              searchVal={SearchVal}
            />
          </CustomTabPanel>
        </Box>
      </Card>

      <CustomDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title={`Select Date Range`}
        fullscreen={false} // Set to true if you want the dialog to be fullscreen
        maxWidth="sm" // Set the maxWidth as needed
      >
        <ExportExcel setOpenDialog={setOpenDialog} />
      </CustomDialog>
    </>
  );
};

export default OrderDetails;
