// css for small card
import "./SmallCard.css";
// mui components
import { Card, Stack, Typography } from "@mui/material";
const SmallCard = ({ icon, count, textContent }) => {
  return (
    <Card>
      <Stack
        direction={"row"}
        gap={2}
        alignItems={"center"}
        justifyContent={"space-between"}
        className="dashboard-small-card"
      >
        <Stack direction={"column"}>
          <Typography className="card-text-total">{count}</Typography>
          <Typography className="card-text-data">{textContent}</Typography>
        </Stack>
        <Stack className="icon-body">{icon}</Stack>
      </Stack>
    </Card>
  );
};
export default SmallCard;
